import React from 'react';
import Home_Banner from '../components/Home_Banner';
import Home_Subbanner from "../components/Home_Subbanner";
import Home_body from '../components/Home_body';
import Home_body_collection from '../components/Home_body_collection';
import Home_IndustrySection from '../components/Home_IndustrySection';

const Home = () => {
  return (
    <div>
        <Home_Banner />
        <Home_Subbanner />
        <Home_body />
        <Home_body_collection />
        <Home_IndustrySection />
    
    </div>
  );
};

export default Home;
