import React from 'react';
import backgroundImage from '../assets/images/HERO.png'; 
import './Home_IndustrySection.css';


const Home_IndustrySection = () => {
    return (
        <div 
          className="container-fluid text-center" 
          style={{ padding: '148px 0' }}
        >
          <div 
            className="mx-auto" 
            style={{ padding: '20px', margin: '0 156px' }}
          >
            {/* Title */}
            <h1 style={{ fontSize: '90px' }}>Changing the INDUSTRY</h1>
    
            {/* Subtitle */}
            <p 
              style={{ 
                fontSize: '36px', 
                margin: '0 112px', 
                padding: '46px 0 56px' 
              }}
            >
              With over 25 years of experience, we specialize in sourcing high-quality talent to meet the unique needs of Fortune 500 and Global 1000 organizations across a range of sectors.
            </p>
    
            {/* Four Sections */}
            <div className="d-flex justify-content-center">
              {/* Section 1: Financial Services, Banking, & Insurance */}
              <div 
                className="px-3" 
                style={{ 
                  fontSize: '25px', 
                  paddingRight: '48px', 
                  borderRight: '1px solid black' 
                }}
              >
                Financial Services, Banking, & Insurance
              </div>
    
              {/* Section 2: Energy, Oil, and Gas */}
              <div 
                className="px-3" 
                style={{ 
                  fontSize: '25px', 
                  paddingRight: '48px', 
                  borderRight: '1px solid black' 
                }}
              >
                Energy, Oil, and Gas
              </div>
    
              {/* Section 3: Healthcare Sector */}
              <div 
                className="px-3" 
                style={{ 
                  fontSize: '25px', 
                  paddingRight: '48px', 
                  borderRight: '1px solid black' 
                }}
              >
                Healthcare Sector
              </div>
    
              {/* Section 4: Telecom, Media, & Technology */}
              <div 
                className="px-3" 
                style={{ fontSize: '25px', paddingRight: '48px' }}
              >
                Telecom, Media, & Technology
              </div>
            </div>
          </div>
        </div>
      );
};

export default Home_IndustrySection;