import React from 'react';


const TermOfUsecontent = () => {
    return (
        <div style={{ paddingTop: '80px', paddingBottom: '90px' }}>
      <div className="container">
        <h1 style={{ fontSize: '50px', paddingBottom: '32px' }}>Terms Of Use</h1>
        <ul className="list-unstyled position-relative" style={{ fontSize: '16px' }}>
          <li style={{ paddingBottom: '32px' }}>
            Unless otherwise stated, the contents of this site including, but not limited to, the text and images contained herein, and their arrangement are the property of Pickcoe. All trademarks used or referred to in this website are the property of their respective owners.
          </li>
          <li style={{ paddingBottom: '32px' }}>
            Nothing contained in this site shall be construed as conferring by implication, estoppel, or otherwise, any license or right to any copyright, patent, trademark or other proprietary interest of Pickcoe or any third party. Pickcoe only grants you, subject to the terms herein, a non-exclusive, non-transferable, limited right to access, use, and display the Pickcoe site and the materials thereon as provided below.
          </li>
          <li style={{ paddingBottom: '32px' }}>
            No proprietary interest of Pickcoe or of a third party in this site may be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way. This includes, but is not limited to, the content provided in this site, including text, video, application look and feel, graphic images, audio, html code, white papers, documents, info articles, etc. except that you may download, display, or print a copy of the materials solely for your non-commercial, personal use.
          </li>
          <li style={{ paddingBottom: '32px' }}>
            The information provided on this site is free of charge and for informational purposes only and does not create an employment, business, or professional services relationship between you and Pickcoe. Links on this site may lead to services or sites not operated by Pickcoe.
          </li>
          <li style={{ paddingBottom: '32px' }}>
            Any personal information or data shall remain your proprietary information, that you provide to Pickcoe or post, upload, input or submit to any section of the Pickcoe site.
          </li>
          <li style={{ paddingBottom: '32px' }}>
            You hereby acknowledge and agree that no compensation shall be paid, or no future commercial consideration has accrued with respect to the use of your personal information or data by Pickcoe, as provided herein.
          </li>
          <li style={{ paddingBottom: '32px' }}>
            Pickcoe does not request or accept unauthorized idea submissions outside of established contracted business relationships.
          </li>
        </ul>
      </div>
    </div>
      );
};

export default TermOfUsecontent;