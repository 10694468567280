import React from 'react';
import backgroundImage from '../assets/images/HERO.png'; 
import './Home_Banner.css';


const Home_Banner = () => {
  return (
    <div className="hero-section d-flex align-items-center justify-content-center" style={{ backgroundImage: `url(${backgroundImage})`, height:'800px', width:'100%' }}>
      <div className="text-center text-white">
        <span className="display-1 " style={{color: '#aa9269', fontStyle: 'italic'}}>Striving for </span><h1 className="display-1 fw-bold"> Excellence</h1>
        <p className="lead fs-3 mt-4">
          In our relentless striving for excellence, we're committed to empowering individuals, fostering elite teams, and nurturing a unique culture of unity and care.
        </p>
        <div className="mt-4">
          <button className="btn btn-outline-light btn-lg me-3">Find Talent</button>
          <button className="btn btn-light btn-lg">Find Job</button>
        </div>
      </div>
    </div>
  );
};

export default Home_Banner;