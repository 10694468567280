import React from 'react';
import backgroundImage from '../assets/images/HERO.png'; 
import './Home_body.css';


const Home_body = () => {
    return (
        <div 
          className="container-fluid" 
          style={{ 
          
            backgroundColor: '#1a6565', 
            padding: '100px' 
          }}
        >
          <div className="row h-100">
            {/* First Section: Pickcoe in a nutshell */}
            <div className="col d-flex align-items-center justify-content-center" style={{ fontSize: '25px', borderRight: '1px solid white', color: 'white', padding:'0 40px'}}>
              Pickcoe in a nutshell
            </div>
    
            {/* Second Section: Best Companies for Diversity */}
            <div className="col d-flex align-items-center justify-content-center" style={{ fontSize: '20px', borderRight: '1px solid white',color: 'white', padding:'0 40px' }}>
              Best Companies for Diversity
            </div>
    
            {/* Third Section: 300+ Clients Globally */}
            <div className="col d-flex align-items-center justify-content-center" style={{ borderRight: '1px solid white',color: 'white', padding:'0 40px' }}>
              <span style={{ fontSize: '48px', marginRight: '16px' }}>300+</span>
              <span style={{ fontSize: '20px' }}>Clients Globally</span>
            </div>
    
            {/* Fourth Section: 60% Fortune 500 Clients */}
            <div className="col d-flex align-items-center justify-content-center" style={{ color: 'white', padding:'0 40px' }}>
              <span style={{ fontSize: '48px', marginRight: '16px' }}>60%</span>
              <span style={{ fontSize: '20px' }}>Fortune 500 Clients</span>
            </div>
          </div>
        </div>
      );
};

export default Home_body;