import React from 'react';
import img1 from '../assets/images/img1.png'; 

import img2 from '../assets/images/img2.png'; 

import img3 from '../assets/images/img3.png'; 

import img4 from '../assets/images/img4.png'; 

import './Home_body_collection.css';


const Home_body_collection = () => {
    return (
        <div 
          className="container-fluid text-center" 
          style={{ 
            padding: '100px 0', 
            backgroundColor: '#d7ecf8' 
          }}
        >
          {/* Title */}
          <h1 style={{ fontSize: '66px', color: '#000' }}>Customized Talent Solutions</h1>
          
          {/* Subtitle */}
          <h2 style={{ fontSize: '36px', color: '#000', padding: '48px 0' }}>Our Suite of Services</h2>
    
          <div className="row" style={{ margin: '0px 156px', padding: '0px 20px' }}>
            {/* Section 1: Staff Augmentation */}
            <div className="col-md-3 col-sm-6" style={{ padding: '0px 15px 40px' }}>
              <img src={img1} alt="Staff Augmentation" style={{ width: '100%', aspectRatio: '1/1' }} />
              <h3 style={{ fontSize: '28px', paddingTop: '32px', fontWeight: 'bold' }}>
                Staff <br /> Augmentation
              </h3>
              <p style={{ fontSize: '25px', padding: '16px 0' }}>
                Scale and flex your team to meet business priorities.
              </p>
              <a href="#" style={{ fontSize: '16px', color: '#1a6565' }}>
                Learn About Staff Augmentation
              </a>
            </div>
    
            {/* Section 2: Direct Placement */}
            <div className="col-md-3 col-sm-6" style={{ padding: '0px 15px 40px' }}>
              <img src={img2} alt="Direct Placement" style={{ width: '100%', aspectRatio: '1/1' }} />
              <h3 style={{ fontSize: '28px', paddingTop: '32px', fontWeight: 'bold' }}>
                Direct <br /> Placement
              </h3>
              <p style={{ fontSize: '25px', padding: '16px 0' }}>
                Accelerate recruiting end-to-end timelines with custom services.
              </p>
              <a href="#" style={{ fontSize: '16px', color: '#1a6565' }}>
                Find Your Next Rockstar Hire
              </a>
            </div>
    
            {/* Section 3: Talent Development and Transformation */}
            <div className="col-md-3 col-sm-6" style={{ padding: '0px 15px 40px' }}>
              <img src={img3} alt="Talent Development" style={{ width: '100%', aspectRatio: '1/1' }} />
              <h3 style={{ fontSize: '28px', paddingTop: '32px', fontWeight: 'bold' }}>
                Talent Development <br /> and Transformation
              </h3>
              <p style={{ fontSize: '25px', padding: '16px 0' }}>
                Upskill a digital-ready workforce with virtual learning solutions.
              </p>
              <a href="#" style={{ fontSize: '16px', color: '#1a6565' }}>
                Reskill & Upskill Your Teams
              </a>
            </div>
    
            {/* Section 4: Global Remote Talent Platform */}
            <div className="col-md-3 col-sm-6" style={{ padding: '0px 15px 40px' }}>
              <img src={img4} alt="Global Remote Talent" style={{ width: '100%', aspectRatio: '1/1' }} />
              <h3 style={{ fontSize: '28px', paddingTop: '32px', fontWeight: 'bold' }}>
                Global Remote <br /> Talent Platform
              </h3>
              <p style={{ fontSize: '25px', padding: '16px 0' }}>
                Scale your business with a global network of 14M+ professionals.
              </p>
              <a href="#" style={{ fontSize: '16px', color: '#1a6565' }}>
                Build A Global Team
              </a>
            </div>
          </div>
        </div>
      );
};

export default Home_body_collection;