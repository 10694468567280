import React from 'react';
import TermOfUsecontent from '../components/TermOfUsecontent';

const TermOfUse = () => {
  return (
    <div>
      
      <TermOfUsecontent />
    </div>
  );
};

export default TermOfUse;