import React from 'react';
import { Container } from 'react-bootstrap';
import logo from '../assets/images/logo.JPG';


const Footer = () => {
  return (

      <footer class='align-items-center justify-content-center'  >
      <div 
       
        style={{ backgroundColor: '#05262b', padding:'124px 0px' }}
      >
        <div className="row" style={{padding: '124px 0', margin: '0 156px', padding: '0 20px'}}>
          
          {/* Section 1: Logo and Description */}
          <div className="col-md-3 col-lg-4">
            <img src={logo} alt="Collabera Logo" style={{ width: '100%' }} />
            <p style={{ padding: '36px 0', fontSize: '16px', color:'white' }}>
            Pickcoe is a premier global provider of digital talent solutions. With over 25 years of experience, we specialize in delivering digital and IT talent services, direct placements, career guidance, and global remote talent and learning solutions, helping to transform and diversify the workforces of Fortune 1000 companies worldwide.
            </p>
            <button 
              className="btn btn-primary" 
              style={{ padding: '20px 36px', fontSize: '16px' }}
            >
              Consultant Login
            </button>
          </div>

          {/* Section 2: About */}
          <div className="col-md-3 col-lg-2" style={{ marginLeft: '8.33%' }}>
            <h4 style={{ color: '#b1f4dc', fontSize: '16px', fontWeight: '700', paddingBottom: '10px' }}>About</h4>
            <ul className="list-unstyled">
              <li style={{ paddingBottom: '8px', fontSize: '20px' }}>
                <a href="/contact-us" style={{ color: '#fff' }}>Contact Us</a>
              </li>
              <li style={{ paddingBottom: '8px', fontSize: '20px' }}>
                <a href="/terms-of-use" style={{ color: '#fff' }}>Terms Of Use</a>
              </li>
              <li style={{ paddingBottom: '8px', fontSize: '20px' }}>
                <a href="#" style={{ color: '#fff' }}>Privacy Notice</a>
              </li>
              <li style={{ paddingBottom: '8px', fontSize: '20px' }}>
                <a href="#" style={{ color: '#fff' }}>LCA posting</a>
              </li>
            </ul>
          </div>

          {/* Section 3: Resources */}
          <div className="col-md-3 col-lg-2" style={{ maxWidth: '16.66%' }}>
            <h4 style={{ color: '#b1f4dc', fontSize: '16px', fontWeight: '700', paddingBottom: '10px' }}>Resources</h4>
            <ul className="list-unstyled">
              <li style={{ paddingBottom: '8px', fontSize: '20px' }}>
                <a href="#" style={{ color: '#fff' }}>Accessibility</a>
              </li>
              <li style={{ paddingBottom: '8px', fontSize: '20px' }}>
                <a href="#" style={{ color: '#fff' }}>Disclaimer</a>
              </li>
              <li style={{ paddingBottom: '8px', fontSize: '20px' }}>
                <a href="#" style={{ color: '#fff' }}>Sitemap</a>
              </li>
            </ul>
          </div>

          {/* Section 4: Contact Information */}
          <div className="col-md-3 ">
            <h4 style={{ color: '#b1f4dc', fontSize: '16px', fontWeight: '700', paddingBottom: '10px' }}>Phones</h4>
            <ul className="list-unstyled">
              <li style={{ paddingBottom: '8px', fontSize: '25px' }}>
                <a href="tel:+917062784703" style={{ color: '#fff' }}>+91-7073417987</a>
              </li>
              <li style={{ paddingBottom: '8px', fontSize: '25px' }}>
                <a href="tel:+917014178526" style={{ color: '#fff' }}>+91-7062784703</a>
              </li>
              <li style={{ paddingBottom: '30px' }}></li>
            </ul>
            <h4 style={{ color: '#b1f4dc', fontSize: '16px', fontWeight: '700', paddingBottom: '10px' }}>Email</h4>
            <ul className="list-unstyled">
              <li style={{ paddingBottom: '8px', fontSize: '25px' }}>
                <a href="mailto:Chhayankiph@gmail.com" style={{ color: '#fff' }}>support@pickcoe.com</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div style={{backgroundColor:'#27717c', color:'white', textAlign:'center' }}>
        <p className="mb-0">© 2024 Consulting Firm. All rights reserved.</p>
        <p className="mb-0">Follow us on: 
          <a href="#" className="text-white ms-2">LinkedIn</a> | 
          <a href="#" className="text-white ms-2">Twitter</a> | 
          <a href="#" className="text-white ms-2">Facebook</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
