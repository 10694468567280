import React from 'react';
import img from '../assets/images/img.jpg'; 
import './Home_Subbanner.css';



const Home_Subbanner = () => {
    return (
        <div className="container-fluid" style={{ height: '534px', background: '#f9ecd8' }}>
          <div className="row h-100" style={{background: '#f9ecd8'}}>
            {/* Left Section: Video or Audio Player */}
            <div className="col-12 col-md-6 d-flex align-items-center justify-content-center " style={{background: '#f9ecd8'}}>
              {/*<video width="100%" height="auto" autoPlay muted loop>
                <source src="your-video-url.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>*/}
              <img
            src={img}
            width="100%"
           
            className="d-inline-block align-top"
            alt="Company Logo"
          />

            </div>
    
            {/* Right Section: Text Content */}
            <div className="col-12 col-md-6 d-flex flex-column align-items-center justify-content-center " style={{background: '#f9ecd8'}}>
              <h1 className="text-center mb-3">We foster meaningful connections to drive impactful change.</h1>
              <p className="text-center">
              As experts in digital talent solutions, our mission is to create positive transformations for businesses and communities worldwide. We would be privileged to be your trusted and unique partner on this journey.
              </p>
            </div>
          </div>
        </div>
      );
};

export default Home_Subbanner;