import React from 'react';
import { Container } from 'react-bootstrap';

const About = () => {
  return (
    <Container className="my-5">
      <h2>About Us</h2>
      <p>
      Pickcoe is a premier global provider of digital talent solutions. With over 25 years of experience, we specialize in delivering digital and IT talent services, direct placements, career guidance, and global remote talent and learning solutions, helping to transform and diversify the workforces of Fortune 1000 companies worldwide.
      </p>
    </Container>
  );
};

export default About;
